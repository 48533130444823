import React from "react";
import Fade from "react-reveal/Fade";
import "./serviceTemplate.css";
const ServiceTemplate = (props) => {
  const { title, body1, body2, body3, body4, pic } = props;
  return (
    <div className="bgContainerService">
      <Fade duration={2500} left>
        <div className="landingPageContainerService">
          <div className="row">
            <div className=" col-lg  ">
              <img className="LogosServices" src={pic} alt="" />
            </div>
            <div className="col-md text-left col-lg  ">
              <h1
                className="titleService"
                style={{ fontWeight: "bold", fontSize: "50px" }}
              >
                {title}
              </h1>
              <div className="subtitleService">
                <p> {body1}</p>
                <p style={{ color: "" }}> {body2}</p>
                <p>{body3}</p>
                <p>{body4}</p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ServiceTemplate;
