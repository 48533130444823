import React from "react";
// import Hero from "../home/hero";
import NavBar from "../navbar/navbar";
import WelcomePage from "../welcomePage/welcomePage";
import AboutUs from "../aboutUs/aboutUs";
import Footer from "../footer/footer";
import CardServices from "../service/ServiceCard";
import "./heroStyles.css";

import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const hero = () => {
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <NavBar />
      <div className="bgContainerHero">
        <WelcomePage />
        <AboutUs />
        <CardServices />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default hero;
