import React from "react";
import background from "../../assets/BottomBg6.png";
import { Container } from "react-bootstrap";
import instagram from "../../assets/instagramBlack.png";
import facebook from "../../assets/facebookBlack.png";
import linkedin from "../../assets/linkedinLogoBlack.png";
import "./footerStyles.css";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#E0E0CD" }}>
      <Container className="footerContainer">
        <footer className=" text-center    ">
          <div className="row ">
            <div className="col-md-6 mt-md-4 mt-3">
              <h5 className="text-uppercase footerTitles">Contact us</h5>
              <p className="footer-font">
                <a href="mailto:info@marinezoneegypt.com">
                  <strong>
                    <span className="text-dark">info@marinezoneegypt.com</span>
                  </strong>
                </a>
              </p>

              <p className="footer-font d-inline ">
                {" "}
                <a href="tel:+201000002128" style={{ color: "black" }}>
                  <strong> EG: +201000002128 </strong>
                </a>
              </p>
            </div>

            <div className="col-md-6 mt-md-4 footerLower">
              {/* <h5 className="text-uppercase footerTitles"></h5> */}

              <p className="footer-font">
                <p
                  style={{
                    fontFamily: "Fira Sans",
                    fontWeight: "bold",
                    marginTop: "30px",
                  }}
                >
                  {/* <a
                  href="https://goo.gl/maps/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark ah"
                  style={{
                    color: "black !important",
                    textDecoration: "none",
                  }}
                > */}
                  Committed to maritime excellence. Tailored solutions.
                  High-quality products. Exceptional service.
                </p>
                {/* </a> */}
              </p>
            </div>
          </div>

          <div className="pb-4">
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              <img
                src={facebook}
                width="25px"
                alt="Facebook logo"
                className="m-2"
              />
            </a>

            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={instagram}
                width="25px"
                className="m-2"
                alt="instagram logo"
              />
            </a>

            <a href="http://www.linkedin.com" target="_blank" rel="noreferrer">
              <img
                src={linkedin}
                width="25px"
                className="m-2"
                alt="linked logo"
              />
            </a>
          </div>
          <div className="row picFooter ">
            <img src={background} alt="background bottom" />
          </div>

          <div className="footer-copyright">
            © 2023 Copyright:
            <a href="#home" target="_blank" rel="noreferrer">
              {" "}
              marinezoneegypt.com{" "}
            </a>
          </div>
        </footer>
      </Container>
    </div>
  );
};

export default Footer;
