import { Container } from "react-bootstrap";
import shipBrokerage from "../../assets/shipBrokerage.gif";
import technicalSpareParts from "../../assets/technicalSpareParts.gif";
import riggingGear from "../../assets/riggingGear.gif";
import CardTemplate from "./CardTemplate";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import staff from "../../assets/Staff.jpg";

import "swiper/css/pagination";
import "swiper/css";
import "swiper/less/navigation";
import "swiper/css/effect-fade";
import "./serviceCard.css";

function cardServices() {
  return (
    <div className="bgContainer">
      <Container className="serviceCardContainer">
        <h1 className="titleServices">Services</h1>
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 1,
            },
            720: {
              width: 640,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            830: {
              width: 1000,
              slidesPerView: 2,
            },
          }}
          spaceBetween={50}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          className="myswiper"
          style={{ paddingBottom: "30px" }}
        >
          <SwiperSlide style={{ color: "white", marginLeft: "10px" }}>
            {" "}
            <CardTemplate
              pic={shipBrokerage}
              title={<p>Ship brokerage</p>}
              body={
                <p>
                  We offer ship brokerage services for various vessels,
                  providing expert guidance and efficient transactions to meet
                  our clients' unique needs.
                </p>
              }
            />
          </SwiperSlide>
          <SwiperSlide style={{ color: "black" }}>
            {" "}
            <SwiperSlide style={{ color: "white", marginLeft: "10px" }}>
              {" "}
              <CardTemplate
                pic={staff}
                title={<p>Staffing Service</p>}
                body={
                  <p>
                    We provide staffing solutions for all types of ships, with
                    highly skilled crew members who ensure smooth sailing and
                    successful voyages.
                  </p>
                }
              />
            </SwiperSlide>
          </SwiperSlide>
          <SwiperSlide style={{ color: "black" }}>
            {" "}
            <SwiperSlide style={{ color: "white", marginLeft: "10px" }}>
              {" "}
              <CardTemplate
                style={{ height: "50px" }}
                pic={riggingGear}
                title={<p>Imports/Exports</p>}
                body={
                  <p>
                    {" "}
                    We offer import and export services for steel, rigging gear,
                    and more, providing tailored solutions and delivering
                    high-quality products from around the world.
                  </p>
                }
              />
            </SwiperSlide>
          </SwiperSlide>
          <SwiperSlide style={{ color: "black" }}>
            {" "}
            <SwiperSlide style={{ color: "white", marginLeft: "10px" }}>
              {" "}
              <CardTemplate
                pic={technicalSpareParts}
                title={<p>Logistic Support</p>}
                body={
                  <ul>
                    {" "}
                    <li>Technical Spare Parts</li>{" "}
                    <li>Navigation Equipments </li>
                  </ul>
                }
              />
            </SwiperSlide>
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
}

export default cardServices;
