import React from "react";
import "./welcomePageStyles.css";
import ship from "../../assets/shiptest3.mp4";
const WelcomePage = () => {
  return (
    <div className="welcome-page">
      <video autoPlay loop muted className="video-background">
        <source src={ship} type="video/mp4" />
      </video>
      <div className="content">
        <h1 className="titleWelcome">Welcome to Marine Zone Egypt</h1>
        <p className="subTitle">
          We provide professional marine brokerage services.
        </p>
        <p className="subTitle">Serving the maritime industry since 2001</p>
      </div>
    </div>
  );
};

export default WelcomePage;
