import React from "react";
import NavBar from "../navbar/navbar";
import Footer from "../footer/footer";
import "./servicesPage.css";
import shipBrokerage from "../../assets/shipBrokerage1.gif";
import riggingGear from "../../assets/riggingGear.gif";

import staff from "../../assets/Staff.jpg";
import ServiceTemplate from "./servicePageTemplate.jsx";
import ServiceTemplateRev from "./servicePageTemplateRev";
import technicalSpareParts from "../../assets/technicalSpareParts.gif";

import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const ServicesPage = () => {
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div style={{ backgroundColor: "black" }}>
        <div className="welcome-pageService ">
          <NavBar />
          <div className="service-background"> </div>
          <div className="content">
            <h1 className="titleWelcomeService">Services</h1>
            <p className="subTitle">
              We Offer various number of services to help out customers
            </p>
            {/* <p className="subTitle">Serving the maritime industry since 2001</p> */}
          </div>
        </div>
        <div className="servicePageBg">
          <ServiceTemplate
            pic={shipBrokerage}
            title={<p>Ship brokerage</p>}
            body1={
              <p>
                Our ship brokerage service is committed to providing the highest
                level of support and expertise in the maritime industry. Our
                team of experienced professionals has the knowledge and skills
                necessary to handle all aspects of ship brokerage. Our goal is
                to make the process as easy and stress-free as possible for our
                clients.
              </p>
            }
            body2={
              <p>
                We specialize in brokerage services for a wide range of ship
                types, including passenger ships, supply boats, tug boats,
                tankers, general cargo, and jack up rigs. Our team has the
                resources and expertise to meet the needs of our clients,
                whether they are in need of brokerage for a single vessel or a
                fleet.
              </p>
            }
            body3={
              <p>
                Our mission is to simplify the process and allow our clients to
                focus on their core business while we handle the details of the
                brokerage. We strive to provide the highest level of service and
                support to ensure the success of our clients' maritime
                operations. Contact us today to learn more about our
                comprehensive ship brokerage services.
              </p>
            }
          />{" "}
          <ServiceTemplateRev
            pic={staff}
            title={<p>Staffing Service</p>}
            body1={
              <p>
                we also offer staffing solutions for a variety of ship types.
                Our team of experienced professionals is well-equipped to
                provide top-quality staffing for supply boats and tug boats, as
                well as other types of ships.
              </p>
            }
            body2={
              <p>
                We understand that finding the right crew for your vessel can be
                a time-consuming and challenging task. That's why we offer a
                comprehensive staffing solution that takes the hassle out of the
                process. Our team will work with you to determine your specific
                staffing needs and match you with the best-suited crew members
                for your vessel.
              </p>
            }
            body3={
              <p>
                We believe that the crew is the backbone of any successful
                maritime operation. That's why we are committed to providing
                only the best and most qualified crew members to our clients.
                Our goal is to ensure the safety, efficiency, and success of
                your maritime operations. Contact us today to learn more about
                our staffing solutions for supply boats and tug boats, as well
                as other types of ships.
              </p>
            }
          />
          <ServiceTemplate
            pic={riggingGear}
            title={<p>Imports/Exports</p>}
            body1={
              <p>
                Our company specializes in providing import and export services
                for a diverse range of products, which includes steel, rigging
                gear, and much more. We understand the specific requirements of
                our clients and work closely with them to provide customized
                solutions that suit their needs. Our team has extensive
                knowledge and experience in the import and export industry,
                which allows us to source high-quality products from all around
                the world. We also ensure that the delivery of these products is
                cost-effective and timely.
              </p>
            }
            body2={
              <p>
                We believe in building a strong and long-lasting relationship
                with our clients based on trust, reliability, and
                professionalism. Our commitment towards providing exceptional
                service sets us apart from our competitors. We prioritize our
                clients' satisfaction and ensure that their needs are met
                throughout the import and export process. With a network of
                reliable suppliers, we guarantee high-quality products that meet
                the industry standards. Our team of experts is dedicated to
                providing the best possible solutions for our clients' import
                and export needs.
              </p>
            }
          />{" "}
          <ServiceTemplateRev
            pic={technicalSpareParts}
            title={<p>Supply Logistic Support</p>}
            body1={
              <p>
                Keeping your ship running smoothly and efficiently requires
                access to reliable technical spare parts and navigation
                equipment. That's where we come in. Our company offers a
                comprehensive range of spare parts and navigation equipment for
                all types of ships, ensuring that you always have what you need
                to keep your operations running smoothly.
              </p>
            }
            body2={
              <p>
                From minor repairs to major overhauls, our team of experienced
                professionals has the knowledge and expertise to provide you
                with the right equipment and parts for your specific needs. We
                understand that having access to the right technical support is
                crucial for the success of your maritime operations, which is
                why we are committed to providing only the best products and
                exceptional service.
              </p>
            }
            body3={
              <p>
                We source our technical spare parts and navigation equipment
                from leading manufacturers, ensuring that you have access to the
                highest-quality products available. And our team of experts is
                always available to provide you with the support and advice you
                need to make informed decisions about your equipment and spare
                parts needs. Contact us today to learn more about how we can
                support your maritime operations.
              </p>
            }
          />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ServicesPage;
