import React from "react";
import aboutUspic from "../../assets/aboutCargo4.jpg"; // CPL company and partners logos
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "./aboutUs.css";
const AboutUs = () => {
  return (
    <div className="bgContainer">
      <Fade duration={2500} left>
        <Container className="landingPageContainer">
          <div className="row">
            <div className="col-lg ">
              <img className="Logos" src={aboutUspic} alt="" />
            </div>
            <div className="col-lg  ">
              <h1
                className="title"
                style={{ fontWeight: "bold", fontSize: "50px" }}
              >
                About Us
              </h1>
              <div className="subtitle">
                <p>
                  {" "}
                  Our marine brokerage company is dedicated to providing
                  exceptional services to the shipping and offshore industry
                  worldwide.
                </p>
                <p style={{ color: "" }}>
                  {" "}
                  Our team of experts is well-versed in the latest industry
                  trends and has a deep understanding of the complex needs of
                  the maritime sector.
                </p>
                <p>
                  By offering a comprehensive range of services within
                  chartering, sale and purchase, and contracting of ships and
                  offshore units, we are able to provide our clients with a
                  one-stop solution for all their maritime needs.
                </p>
                <p>
                  With a commitment to quality and a passion for excellence, our
                  company is dedicated to helping our clients navigate the
                  ever-changing waters of the maritime industry with ease.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
    </div>
  );
};

export default AboutUs;
