import React from "react";
import { Routes, Route } from "react-router-dom";
import Hero from "./components/home/hero";

import ServicesPage from "./components/ServicesPage/ServicesPage";
import AboutUsPage from "./components/aboutUsPage/AboutUsPage";
import "./App.css";

function App() {
  return (
    <div>
      <React.Fragment>
        <Routes>
          <Route exact path="/Services" element={<ServicesPage />} />
          <Route exact path="/about" element={<AboutUsPage />} />
          <Route exact path="/home" element={<Hero />} />
          <Route exact path="/" element={<Hero />} />
        </Routes>
      </React.Fragment>
    </div>
  );
}

export default App;
