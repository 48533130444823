import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../footer/footer";
import NavBar from "../navbar/navbar";
import "./aboutUsPage.css";

import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const AboutUsPage = () => {
  return (
    <div className="AboutUsPageBg">
      <ScrollToTopOnMount />
      <NavBar />
      <div className="welcome-pageAboutUs ">
        <div className="AboutUs-background"></div>
        <div className="contentAboutUs">
          <h1 className="titleWelcomeAboutUs">About Us</h1>
          <p className="subTitleAboutUs"></p>
        </div>
      </div>
      <Container className="aboutUsPageContainer">
        <div className="subtitleAboutUsPage">
          <h1 className="titleAboutUs border">About Us</h1>
          <p>
            Marine Zone Egypt is a leading provider of maritime services,
            offering a comprehensive range of solutions to support your
            operations. We are a team of experienced professionals with a deep
            understanding of the maritime industry, and we are dedicated to
            delivering high-quality services and solutions that meet the unique
            needs of each of our clients.
          </p>
          <p>
            With over 20 years of experience in the industry, our CEO has a
            proven track record of success and has established a reputation for
            delivering results. He has been working in the industry since 2001
            and has a deep understanding of the challenges and opportunities
            that you face.
          </p>
          <h1 className="titleAboutUs border">Dedicated to Your Successs</h1>
          <p>
            We are dedicated to the success of our clients and are committed to
            providing you with the services and solutions you need to achieve
            your goals. Our team of experts is always available to provide you
            with the support and advice you need, and we work closely with you
            to understand your specific requirements so that we can deliver the
            best possible results.
          </p>
          <p>
            Whether you're looking for ship brokerage, staffing, technical spare
            parts and navigation equipment, or other services, we have the
            resources and expertise to help you achieve your objectives. Our
            goal is to help you succeed, and we are proud to be a partner you
            can count on for all of your maritime needs.
          </p>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default AboutUsPage;
