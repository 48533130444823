import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./serviceCard.css";
function CardTemplate(props) {
  const { title, body, pic } = props;
  return (
    <Card
      style={{ width: "18rem", borderRadius: "20px" }}
      className="row bg-dark"
    >
      <Card.Img
        variant="top"
        src={pic}
        style={{
          borderRadius: "20px",
          marginTop: "10px",
          width: "300px",
          height: "250px",
        }}
      />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{body}</Card.Text>
        <a href="/services">
          <Button className="text-dark">For more Details</Button>
        </a>
      </Card.Body>
    </Card>
  );
}

export default CardTemplate;
